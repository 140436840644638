export function showToastAlerts($alerts) {

    const loaderColors = {
        'success': '#f96868',
        'info': '#46c35f',
        'warning': '#57c7d4',
        'error': '#f2a654',

    }

    $alerts.each((i, alert)=>{
        let $alert = $(alert);
        $.toast({
//          heading: $alert.data('alert-type') ,
          text: $alert.text(),
          showHideTransition: 'slide',
          icon: $alert.data('alert-type'),
          loaderBg: loaderColors[$alert.data('alert-type')],
          position: 'top-right',
          hideAfter: 10000
        });
    });

}