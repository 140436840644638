import $ from 'jquery';
// const $ = require('jquery');
global.$ = global.jQuery = $;
window.$ = window.jQuery = $;
import "bootstrap/dist/js/bootstrap.bundle";
import "jquery-toast-plugin";
import { handleCookieBar } from './cookieBar.js';
import { showToastAlerts } from './alerts.js';


(function($) {

    const isMobile = ('ontouchstart' in document.documentElement && navigator.userAgent.match(/Mobi/))
    if(isMobile){
        $('body').addClass('mobile');
    } else {
        $('body').addClass('desktop');
    }

    // Pretty checkbox
    $(".form-check label input, .form-radio label input").after('<i class="input-helper"></i>');

    showToastAlerts($('.toast-alert'));

    handleCookieBar();

})(jQuery);